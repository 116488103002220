import { useState, useEffect, useRef } from "react";
import ChakraDropdown from "./ChakraDropdown.jsx";


const ChakrasInteractive = ({ items, classI }) => {
  const [dropdown, setDropdown] = useState(false);
  let ref = useRef();

  // To scroll to element
  const myRef = useRef(null);

  useEffect(() => {

    const handler = (event) => {

      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    document.addEventListener("mouseout", handler);

    return () => {
      // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);

        // To Scroll to element every time you click
        myRef.current.scrollIntoView() 

    };
  }, [dropdown]);

  

  return (
        <div className={classI}>

            <button type="button" aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={() => setDropdown((prev) => !prev)}>
                <img src={items.img} alt="" />
            </button>
            
            <ChakraDropdown custom_ref={myRef} submenus={items.submenu} dropdown={dropdown} />
        </div>
  );
};

export default ChakrasInteractive;
