import React, { useState, useEffect } from 'react';

function ChatGPT() {

  useEffect(() => {
    getEngines();
  }, []);

  const [input, setInput] = useState("");
  const [models, setModels] = useState([]);
  const [currentModel, setCurrentModel] = useState("text-davinci-003");
  const [chatLog, setChatLog] = useState([]);
  // const [chatHistory, setChatHistory] = useState([]);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [dialogueBox, setDialogueBox] = useState(true);
  const [disableInput, setDisableInput] = useState(false);

  function openDialogueBox(){
      // setDialogueBox(!dialogueBox);

      if(acceptTerms!==true){
        setAlertPopUp(true)
      }

  }

  function acceptedTerms(){
    setAcceptTerms(true);
    setAlertPopUp(false);
    console.log("The user accepted the terms regarding ChatGPT");
  }

  async function handleSubmit(e){
    e.preventDefault();
    // open the chatbox
    setDialogueBox(true);

    // Limit the amount of msgs for free trial
    if(chatLog.length >= 4){
      setDisableInput(true);
      alert('Gracias por hacer la prueba gratuita!')
    }

    // create the msg event
    let chatLogNew = [...chatLog, { user: 'Yo', message: `${input}`, timestamp: Date.now()}];
    setInput("");
    setChatLog(chatLogNew);

    const messages = chatLogNew.map((message) => message.message).join("\n");

    const chatHist = chatLog.map((item)=>{
      return {
        user: item.user,
        msg: item.message
      }
    });

    // For testing http://localhost:3500/chat & https://my-dev-gpt.herokuapp.com/chat

    const response = await fetch("http://localhost:3500/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
      },
      body: JSON.stringify({
        message: messages,
        currentModel: currentModel,
        chatLog: chatLog
      })
    })
    .catch(error => {
        console.log(error);
    });

    const data = await response.json();
    setChatLog([...chatLogNew, { user: "Laetus", message: `${data.message}`, timestamp: Date.now()}]);
    
    console.log("CHAT LOG = = = = ", chatLog);

    // console.log("CHAT LOGGGGGG" + chatHist[0].user);
  }

  function getEngines(){
    fetch("http://localhost:3500/models")
    .then(res => res.json())
    .then(data => setModels(data.models))
    .catch(error => {
        console.log(error);
    });
  }

  return (
    <div className="chat-gpt-component">

      <section className='chatbox'>

      {alertPopUp ?  <div className="alert-popup">
        <p>Acepto que mis conversaciones sean guardadas</p>
          <button className='alert-popup-btn' onClick={acceptedTerms} >Ok</button>
      </div>
      :
      null}

        {/* <div className="expand-clear-buttons">
            <button className='expand-button' onClick={openDialogueBox}>
                {dialogueBox ? ' ↓' : '↑'}
            </button>
        </div> */}

        <div className='chat-input-holder'>
          <form onSubmit={handleSubmit} action="">
            <input
            rows="1"
            value={input}
            onClick={openDialogueBox}
            onChange={(e)=> setInput(e.target.value)}
            disabled={disableInput}

            className='chat-input-textarea'
            placeholder='Escribe tus pensamientos negativos aquí'>
            </input>
            
          </form>
        </div>

        <div className="chat-log">

          {chatLog.map((message, index)=>{
            return <ChatMessage  message={message} key={index}/>
          })}
  
        </div>

      </section>
      
    </div>
  );
}

// Component
const ChatMessage = ({ message }) => {
  return (
          <div className={`chat-message ${message.user === "Laetus" && "chatgpt"}`}>
            <div className="chat-message-center">
              <div className={`avatar ${message.user === "Laetus" && "chatgpt"}`}>
                {message.user}
              </div>
              <div className='message'>
                <p>{message.message}</p>
              </div>
            </div>
          </div>
  )
}

export default ChatGPT;