import React, { useState } from 'react';

const Footer = () => {

  const [showPopUp, setShowPopUp] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText('san.loa@outlook.com')
}

  return (
    <div className='footer_wrapper'>
        <div className="footer">
            <div className="button_wrapper">
              <button onClick={copyToClipboard} onMouseEnter={()=> setShowPopUp(true)} onMouseLeave={()=>setShowPopUp(false)}>san.loa@outlook.com</button>
              {showPopUp ?
                <div className="pop_up_footer">
                  <p>Clickear para COPIAR email</p>
                </div>
                : null}
            </div>
            <a href="https://www.instagram.com/san_reikista">IG</a>
            <a href="https://www.tiktok.com/@santi.loa">TikTok</a>
        </div>
    </div>
  )
}

export default Footer;