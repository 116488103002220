import React from 'react';

import Header from '../components/spanish/Header.jsx';
import Hero from '../components/spanish/Hero.jsx';
import Testimonials from '../components/spanish/Testimonials.jsx';
import Footer from '../components/spanish/Footer.jsx';

import Container from '../components/english/Container.jsx';



const Home = () => {
  return (

    <div className='home'>

        <Header/>
        <Hero/>
        <Testimonials/>
        
        {/* <Myths/> */}
        {/* <Content/> */}

        <Footer/>

        {/* Animation */}

       <Container/>
        
    </div>
    
  )
}

export default Home;