import React from 'react';

import Header from '../components/english/Header.jsx';
import Hero from '../components/english/Hero.jsx';
import Testimonials from '../components/english/Testimonials.jsx';


import Container from '../components/english/Container.jsx';



const Home = () => {
  return (

    <div className='home'>

        <Header/>
        <Hero/>
        <Testimonials/>
        
        {/* <Myths/> */}
        {/* <Content/> */}

        {/* Animation */}

       <Container/>

       <br />
        
    </div>
    
  )
}

export default Home;