export const chakras_info = [
    {
        img: '/img/chakras_symbols/1.png',
        submenu: [
            {
                title: '1. Root Chakra',
                description: 'Physical Body, Survival, Financial Independence, Stability, Self-confidence.',
                color: 'Red',
                bg_color: 'radial-gradient(rgb(213, 53, 53) 50%, rgba(255,0,0,0))',
                energy_body: 'Etheric Body'
            },
        ]
    },
    {
        img: '/img/chakras_symbols/2.png',
        submenu: [
            {
                title: '2. Sacral Chakra',
                description: 'Sexuality, Creativity, Pleasure',
                color: 'Orange',
                bg_color: 'radial-gradient(rgb(213, 122, 53)50%, rgba(255,0,0,0))',
                energy_body: 'Emotional Body'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/3.png',
        submenu: [
            {
                title: '3. Solar Plexus Chakra',
                description: 'Sensations, Relationships, Self-Esteem',
                color: 'Yellow',
                bg_color: 'radial-gradient(rgb(236, 222, 62)50%, rgba(255,0,0,0))',
                energy_body: 'Mental Body'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/4.png',
        submenu: [
            {
                title: '4. Heart Chakra',
                description: 'Emotions, Love, Compassion',
                color: 'Green',
                bg_color: 'radial-gradient(rgb(146, 236, 62)50%, rgba(255,0,0,0))',
                energy_body: 'Astral Body'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/5.png',
        submenu: [
            {
                title: '5. Throat Chakra',
                description: 'Expression, Communication',
                color: 'Indigo',
                bg_color: 'radial-gradient(rgb(62, 137, 236)50%, rgba(255,0,0,0))',
                energy_body: 'Etheric Template'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/6.png',
        submenu: [
            {
                title: '6. Third Eye Chakra',
                description: 'Intuition, Imagination,   Psychic Abilities',
                color: 'Purple',
                bg_color: 'radial-gradient(rgb(103, 62, 236)50%, rgba(255,0,0,0))',
                energy_body: 'Celestial Body'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/7.png',
        submenu: [
            {
                title: '7. Crown Chakra',
                description: 'Awareness, Intelligence, Connection with other dimensions',
                color: 'violet',
                bg_color: 'radial-gradient(rgb(192, 62, 236)50%, rgba(255,0,0,0))',
                energy_body: 'Causal Body'
            }
        ]
    },
]