import React from 'react';

const Header = () => {

  return (
    <div className='header'>
        
          <nav>
            <a href="/es">Home</a>
            <a href="/es#sobre_reiki">Sobre Reiki</a>
            <a href="/es#sobre_mi">Sobre Mí</a>
            <a href="/chakras_esp">Chakras</a>
            <a href="/chat_esp">Laetus</a>
            <a href="https://linktr.ee/santi.loa" target={'_blank'} rel="noreferrer">Links</a>
            <a href="/frases">Frases Positivas</a>

            {/* <a href="/#myths">Myths & Questions</a> */}
          </nav>     

        <div className="location">
          <a href="/">English</a>
          {/* <p>Currently in: Buenos Aires, Argentina 🇦🇷</p> */}
        </div>
    </div>
  )
}

export default Header;