export const testimonials = [
    {
        testimonial: '"The benefits I felt immediately after the session grew stronger in the following days. Mental clarity, peace, the ability to "tackle" the problems that arose without them impacting my inner peace... 🙏🏼 infinitely grateful."',
        name: "Patricia"
    },
    {
        testimonial: '"The reiki session with Santiago was very special, creating a warm and supportive atmosphere in which I felt accompanied and safe. During the session, I felt a deep connection with my body and the present moment, experiencing unique sensations that we were able to discuss afterward. In the following days, I clearly noticed the difference, feeling at peace and in harmony with myself. I am very grateful for the experience."',
        name: "Agustina"
    },
    {
        testimonial: '"I had a fantastic Reiki session with Santiago. Not only did I feel incredibly relaxed and rejuvenated afterward, but I also noticed a significant reduction in my stress levels. I highly recommend Santiago to anyone looking to improve their overall well-being."',
        name: "Alejandro"
    },
    {
        testimonial: '"I entered the session crying, very sad, due to bad health news. When it ended, I went back home and started laughing out loud. I felt liberation and lightness. Despite the news I had received, my body celebrated. It allowed me to go through the situation with great calm and positivity."',
        name: "Anónima"
    },
    {
        testimonial: '"I felt an energy rising through my body, vibrating within me, and healing me. At one point, I fell asleep due to the level of relaxation, and when I woke up, I felt brand new."',
        name: "Cristina"
    },
    {
        testimonial: "I didn't believe in anything until I tried it and was amazed. The effect it has is truly incredible.",
        name: "Gastón"
    },
    {
        testimonial: '"During the session, I only felt relaxation and was able to disconnect from the outside world for a while. The magic in my case happened at night; I had dreams that revealed long-standing blockages, allowing me to work on them!"',
        name: "María"
    },
]