export const chakras_info = [
    {
        img: '/img/chakras_symbols/1.png',
        submenu: [
            {
                title: '1. Chakra Raíz',
                description: 'Cuerpo Físico, Supervivencia, Independencia Financiera, Estabilidad, Confianza.',
                color: 'Rojo',
                bg_color: 'radial-gradient(rgb(213, 53, 53) 50%, rgba(255,0,0,0))',
                energy_body: 'Cuerpo Etérico',
                link: 'https://www.anahana.com/es/yoga/root-chakra'
            },
        ]
    },
    {
        img: '/img/chakras_symbols/2.png',
        submenu: [
            {
                title: '2. Chakra Sacro',
                description: 'Sexualidad, Creatividad, Placer',
                color: 'Naranja',
                bg_color: 'radial-gradient(rgb(213, 122, 53)50%, rgba(255,0,0,0))',
                energy_body: 'Cuerpo Emocional',
                link: 'https://www.anahana.com/es/yoga/sacral-chakra?hsLang=es'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/3.png',
        submenu: [
            {
                title: '3. Chakra Solar',
                description: 'Sensaciones, Relaciones, Auto-estima',
                color: 'Amarillo',
                bg_color: 'radial-gradient(rgb(236, 222, 62)50%, rgba(255,0,0,0))',
                energy_body: 'Cuerpo Mental',
                link: 'https://www.anahana.com/es/yoga/solar-plexus-chakra?hsLang=es'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/4.png',
        submenu: [
            {
                title: '4. Chakra del Corazón',
                description: 'Emociones, Amor, Compasión',
                color: 'Verde',
                bg_color: 'radial-gradient(rgb(146, 236, 62)50%, rgba(255,0,0,0))',
                energy_body: 'Cuerpo Astral',
                link: 'https://www.anahana.com/es/yoga/heart-chakra?hsLang=esn'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/5.png',
        submenu: [
            {
                title: '5. Chakra de la Garganta',
                description: 'Expresión, Comunicación',
                color: 'Azul',
                bg_color: 'radial-gradient(rgb(62, 137, 236)50%, rgba(255,0,0,0))',
                energy_body: 'Plantilla Etérica',
                link: 'https://www.anahana.com/es/yoga/throat-chakra?hsLang=es'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/6.png',
        submenu: [
            {
                title: '6. Chakra del Tercer Ojo',
                description: 'Intuición, Imaginación, Habilidades Psíquicas',
                color: 'Índigo',
                bg_color: 'radial-gradient(rgb(103, 62, 236)50%, rgba(255,0,0,0))',
                energy_body: 'Cuerpo Celestial',
                link: 'https://www.anahana.com/es/yoga/third-eye-chakra?hsLang=es'
            }
        ]
    },
    {
        img: '/img/chakras_symbols/7.png',
        submenu: [
            {
                title: '7. Chakra de la Coronilla',
                description: 'Consciencia, Inteligencia, Conexión con otras dimensiones',
                color: 'Violeta',
                bg_color: 'radial-gradient(rgb(192, 62, 236)50%, rgba(255,0,0,0))',
                energy_body: 'Cuerpo Causal',
                link: 'https://www.anahana.com/es/yoga/crown-chakra?hsLang=es'
            }
        ]
    },
]