import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

  return (
    <div className='header'>
        
          <nav>
            <a href="/">Home</a>
            <a href="/#about_reiki">About Reiki</a>
            <a href="/#about_me">About Me</a>
            <Link to="/chakras"><a>Chakras Model</a></Link>
            <a href="https://linktr.ee/santi.loa" target={'_blank'} rel="noreferrer">Links</a>

            {/* <a href="/#myths">Myths & Questions</a> */}
          </nav>
          
          
        

        <div className="location">
          <a href="/es">Español</a>
          {/* <p>Currently in: Buenos Aires, Argentina 🇦🇷</p> */}
        </div>
    </div>
  )
}

export default Header