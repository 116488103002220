export const testimonials = [
    {
        testimonial: '"Los beneficios que sentí inmediatamente después de la sesión, cobraron fuerza en los días siguientes. Claridad mental, paz, capacidad de “atajar” los problemas que se presentaron sin que impactaran en mi paz interior… 🙏🏼agradecida infinitamente."',
        name: "Patricia"
    },
    {
        testimonial: '"La sesión de reiki con Santiago fue muy especial, generó un clima previo muy contenedor en el que me sentí acompañada y segura. Durante la sesión senti profunda conexión con mi cuerpo y con el momento presente, experimenté sensaciones únicas que luego pudimos conversar en conjunto. Los días siguientes noté claramente la diferencia sintiendome en paz y armonía conmigo misma, muy agradecida por la experiencia."',
        name: "Agustina"
    },
    {
        testimonial: '"Tuve una sesión de Reiki fantástica con Santiago. No solo me sentí increíblemente relajado y rejuvenecido después, sino que también noté una reducción significativa en mis niveles de estrés. Recomiendo encarecidamente a Santiago para cualquiera que busque mejorar su estado general bienestar."',
        name: "Alejandro"
    },
    {
        testimonial: '"Entré a la sesión llorando, muy triste, por una mala noticia de salud. Cuando terminó, volví a mi casa y empecé a reírme a carcajadas. Sentía liberación y liviandad. A pesar de la noticia que había recibido, mi cuerpo celebraba. Me permitió atravesar la situación con mucha calma y positividad"',
        name: "Anónima"
    },
    {
        testimonial: '"Sentí una energía que subía por mi cuerpo y vibraba por dentro, y me sanaba. En un punto me dormí por el nivel de relajación, y cuando me desperté me sentía como nueva."',
        name: "Cristina"
    },
    {
        testimonial: '"No creía en nada hasta que lo probé y quedé impresionado. Es realmente increíble el efecto que tiene."',
        name: "Gastón"
    },
    {
        testimonial: '"Durante la sesión solo sentí relajación y pude desconectar del afuera un rato. La magia en mi caso vino a la noche, tuve sueños que me revelaron bloqueos que traía de hace mucho tiempo y así pude trabajarlos!"',
        name: "María"
    },
]