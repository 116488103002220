import React from 'react';
import Header from "../components/spanish/Header";
import SentenceMover from '../components/spanish/SentenceMover.jsx';


const SentencesSpa = () => {
  return (
    <div className='sentences-wrapper'>
        <Header/>
        <SentenceMover/>
    </div>
  )
}

export default SentencesSpa