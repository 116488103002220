import React from 'react'

const CircleContainer = () => {
  return (
    <div className='circle-container'>
        <div className="circle"></div>
    </div>
  )
}

export default CircleContainer