const ChakraDropdown = ({ submenus, dropdown, custom_ref }) => {

    return (
            <ul ref={custom_ref} className={`dropdown ${dropdown ? "show" : ""}`}>
                {submenus.map((submenu, index) => (
                    <a href={submenu.link} key={index} className="submenu-items" style={{backgroundImage: submenu.bg_color}} >
                        <h3>{submenu.title}</h3>
                        <br />
                        <div className="chakra_color">
                            <p>Color: {submenu.color}</p>
                            <div className="color" style={{backgroundImage: submenu.bg_color}}>

                            </div>
                        </div>
                        <br/>
                        <h3>Keywords</h3>
                        <p>{submenu.description}</p>
                        <br/>
                        <h3>Energetic Body:</h3>
                        <p>{submenu.energy_body}</p>
                    </a>
                ))}
            </ul>
    );
};

export default ChakraDropdown;