import React from 'react';
// import 

function Testimonial(props) {
  return (
    <div className='testimonial'>
        <p className="testimnoial_text">{props.testimonial}</p>
        <p className='testimonial_name'><b>{props.name}</b> </p>
    </div>
  )
}

export default Testimonial;