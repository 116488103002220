import './styles/App.scss';
import { Route, Routes } from 'react-router-dom';


// Pages
import Home from './pages/Home';
import HomeSpa from './pages/HomeSpa';
import Chakras from './pages/Chakras';
import ChakrasSpa from './pages/ChakrasSpa';
// import Chat from './pages/Chat';
import ChatSpa from './pages/ChatSpa';
import SentencesSpa from './pages/SentencesSpa';

function App() {
  return (
    
    <Routes>
        <Route path="/" exact={true} element={<Home/>}/>
        <Route path="/es" exact={true} element={<HomeSpa/>}/>

        <Route path="/chakras" exact={true} element={<Chakras/>}/>
        <Route path="/chakras_esp" exact={true} element={<ChakrasSpa/>}/>
        <Route path="/frases" exact={true} element={<SentencesSpa/>}/>

        {/* <Route path="/chat" exact={true} element={<Chakras/>}/> */}
        <Route path="/chat_esp" exact={true} element={<ChatSpa/>}/>
    </Routes>
  );
}

export default App;
