import React, { useState } from 'react';
import Header from "../components/spanish/Header";
import ChatGPT from "../components/spanish/ChatGPT";
import Footer from "../components/spanish/Footer";


const ChatSpa = () => {

  const [password, setPassword] = useState('');

  return (
    <div className='chat-page'>
      <Header />

      <h3>Todo está bien, confía en tu proceso</h3>

      <p className='chat-page-p'> <b>Laetus</b>, la inteligencia artificial positiva, te va a permitir convertir cualquier pensamiento negativo en algo positivo!</p>
      <p className='chat-page-p'>Siempre está ahí para vos.</p>


      {password == 'loa' ? <ChatGPT /> : <Pass func={(e)=>setPassword(e.target.value)}/> }

      <Footer />

    </div>
  )
}

const Pass = ({ func }) => {
  return (
    <div className='password-box'>
      <input
        onChange={func}
        placeholder='ingresar password'>

      </input>
    </div>
  )
}

export default ChatSpa;