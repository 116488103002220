// sentences.js
const sentences = [
  {
    id: 1,
    text: "Vinimos a este mundo a ser felices, y un ser feliz jamas esta enfermo, es perfecto ♥️es de un libro pero me encanta!",
    author: "Dai Salas"
  },
  {
    id: 2,
    text: "Soy Amor",
    author: "Deigomer"
  },
  {
    id: 3,
    text: "Si no está bajo mi control, mejor dejo que todo fluya!",
    author: "Fabiana Torrez"
  },
  {
    id: 4,
    text: "Cada segundo el universo me provee",
    author: "mariaalbarenque69"
  },
  {
    id: 5,
    text: "Todo llega a mi con facilidad, gozo y gloria.",
    author: "Flor Robles"
  },
  {
    id: 6,
    text: "Hoy será un día maravilloso",
    author: "eugerandazzo"
  },
  {
    id: 7,
    text: "Todo llega a mi con facilidad, gozo y gloria.",
    author: "Flor Robles",
    color: "#CCFF99"
  },
  {
    id: 8,
    text: "La suerte es amiga de la acción 🎶🎶",
    author: "dlais"
  },
  {
    id: 9,
    text: "Todo está Bien! Todo va a estar Bien! Todo es por un Bien Mayor para Mí y los que me Rodean🙏🙌🌈👼🏽💜",
    author: "Elisa Rojas"
  },
  {
    id: 10,
    text: "Soy suficiente y el universo siempre conspira a mi favor",
    author: "ivannadehudson"
  },
  {
    id: 11,
    text: "Lo siento, perdona, gracias, te amo. 😁",
    author: "Dr.Rincón"
  },
  {
    id: 12,
    text: "Nada es para siempre todo pasa. 😁",
    author: "yanis"
  },
  {
    id: 13,
    text: "La frase q digo todas las mañanas es gracias por un nuevo día",
    author: "lorenacastro2023"
  },
  {
    id: 14,
    text: "Vos podés, todo va a estar bien.",
    author: "arrobamalena"
  },
  {
    id: 15,
    text: "Todo lo bueno me encuentra siempre. Mi vida es mágica. Puedo manifestar siempre lo que deseo. Tengo todo lo que quiero. Gracias",
    author: "Paula Pienko"
  },
  {
    id: 16,
    text: "soy abundancia y soy una bendicion por eso recibo bendiciones del universo",
    author: "user1000886123192"
  },
  {
    id: 17,
    text: "DIOS PROVEERÁ 🙌🙌",
    author: "user7373179411775"
  },
  {
    id: 18,
    text: "Soy abundancia soy amor soy merecimiento❤️",
    author: "carinaravarotto"
  },

];

export default sentences;

// 
// [
//   {
//     "comment": "soy abundancia y soy una bendicion por eso recibo bendiciones del universo",
//     "author": "user1000886123192"
//   },
//   {
//     "comment": "Soy abundancia soy amor soy merecimiento❤️",
//     "author": "carinaravarotto"
//   },
//   {
//     "comment": "La fidelidad de Dios me persigue",
//     "author": "lilianazabala796"
//   },
//   {
//     "comment": "universo donde tu me pongas yo tengo la capacidad de ser feliz y voy a dar siempre lo mejor de mi",
//     "author": "marcelamonferran1"
//   },
//   {
//     "comment": "Lo estoy haciendo lo mejor que puedo
