// SentenceMover.js
import React, { useState, useEffect } from 'react';
import sentences from '../../sentences';

let colors = ["#000099", "#3399FF", "#FF33FF", "#3311FF", "#FFFF00"];

const SentenceMover = () => {
  // const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  // const handleMouseMove = (e) => {
  //   setMousePosition({ x: e.clientX, y: e.clientY });
  // };

  // useEffect(() => {
  //   document.addEventListener('mousemove', handleMouseMove);
  //   return () => {
  //     document.removeEventListener('mousemove', handleMouseMove);
  //   };
  // }, []);

  // POPULATING the array with TikTok comments
// const [comments, setComments] = useState([]);

// useEffect(() => {
//   async function fetchComments() {
//     const response = await fetch('https://api.tiktok.com/api/comment/list/?aid=7213057772284659718&count=10&cursor=0&item_id=7211528150080064773');
//     const data = await response.json();
//     const commentsArray = data.comments.map((comment) => ({
//       id: comment.cid,
//       text: comment.text,
//       author: comment.user.nickname,
//       likes: comment.digg_count,
//     }));
//     setComments(commentsArray);
//   }

//   fetchComments();
// }, []);

  return (
    <div className="sentence-container">
      <h3>Frases</h3>
      {sentences.map((sentence, key) => (
        <div
          key={sentence.id}
          className="sentence"
        >
         <p style={{color: colors[key%5],}} key={key}>{sentence.text}</p> 
         <p className='author'>{sentence.author}</p>
        </div>
      ))}
    </div>
  );
};

export default SentenceMover;