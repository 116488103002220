import React from 'react';
import CircleContainer from './CircleContainer.jsx';

const Container = () => {
  return (
    <div className='container'>
        
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>
        <CircleContainer/>

    </div>
  )
}

export default Container;