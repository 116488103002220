import React, { useRef } from "react";
import { chakras_info } from "../chakras_spa.js";
import ChakrasInteractive from "../components/spanish/ChakrasInteractive";
import Header from "../components/spanish/Header";

const Chakras = () => {
  return (
    <div className="chakras_page">
        <Header/>

      {/* Cover to avoid Mobile */}
      <div className="mobile_cover">
        <p>NO DISPONIBLE PARA CELULARES</p>
      </div>

      <div className="chakras_text_wrapper">
        <div className="chakras_text">
          <h2>Modelo Interactivo de Chakras</h2>
          <p>
             Los chakras, de forma muy simplificada, son centros de energía en el cuerpo.
            La palabra proviene del sánscrito y significa rueda.
          </p>
          <p>Corresponden a órganos principales, glándulas y conjuntos de nervios. Naturalmente
            están equilibrados pero diferentes situaciones de la vida pueden desequilibrarlos.
            Reiki se presenta como una herramienta y un tratamiento muy útil para reequilibrarlos,
            y devolver el poder curativo natural que tienen nuestros cuerpos.
          </p>
        </div>
      </div>

      <div className="human_lotus">
        <img className="chakras_lotus" src="/img/meditating_lotus.png" alt="human_img" />

        <ul className="chakras_symbols">
          {chakras_info.map((chakra, index) => {
            return <ChakrasInteractive items={chakra} classI={`ch ch${(index+1)}`} key={index} />;
          })}
        </ul>
        
      </div>
    </div>
  );
};

export default Chakras;
