import React, { useState, useEffect } from 'react';
import Testimonial from './Testimonial';
import { testimonials } from '../../testimonials_es';
// import styles from '../../styles/Testimonials.module.css';

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentIndex((prevIndex) =>
  //       prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
  //     );
  //   }, 10000);
  //   return () => clearInterval(timer);
  // }, []);

  const nextTestimonial = () => {
    setCurrentIndex(
      currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1
    );
  };

  return (
    <div className="testimonials_wrapper">
      <h3 className='testimonios-title'>Testimonios</h3>
      <div
        className="testimonials"
        style={{ right: `${currentIndex * 20}%` }}
      >
        {testimonials.map((item, key) => {
          return <Testimonial testimonial={item.testimonial} name={item.name} key={key} />
        })}
      </div>

      {/* <div className="buttons_slider">
        <button className="arrow arrow-left" onClick={prevTestimonial}>
          &larr;
        </button>
        <button className="arrow arrow-right" onClick={nextTestimonial}>
          &rarr;
        </button>
      </div> */}

    </div>
  );
};

export default Testimonials;
