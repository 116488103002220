import React, { useState } from 'react';

const Hero = () => {

  const [showPopUp, setShowPopUp] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText('san.loa@outlook.com')
}

  return (
    <div className='hero'>
        
        <div className="title">
          <h1>REIKISTA</h1>
        </div>

        <div className="hero_banner">
          <p>Santiago</p>
          <h2>Usui Reiki</h2>
          <img src="/img/reiki-symbol.png" alt="reiki symbol" />
        </div>

        <div className="button_wrapper">
          <button className='call_to_action' onClick={copyToClipboard} id="buttonCopy" onMouseEnter={()=> setShowPopUp(true)} onMouseLeave={()=>setShowPopUp(false)}>
            COORDINAR SESIÓN
            </button>
          {showPopUp ?
          <div className="pop_up">
            <p>Area: 📍 Lund, Suecia</p>
            <p>Enviar un EMAIL a vazquezbare@gmail.com para coordinar</p>
          </div>
          : null}
        </div>

        <p style={{color:'white'}}>Clickear este boton para copiar mi Email</p>
        

        <div className="description">
          <h3 id='sobre_reiki'>Sobre Reiki</h3>
          <p>
           Reiki es una forma de sanación a través del flujo de energía vital universal.
            Actúa sobre nuestro cuerpo físico, nuestro cuerpo emocional y nuestra mente, por lo que durante una sesión
            puedes esperar sentir emociones, o tener pensamientos específicos que llamen tu atención,
            pero sobre todo, la gente siente una profunda sensación de relajación y paz. Lo que está sucediendo a nivel energético
            es que el Reikista está pidiendo energía vital universal que le llega a través de su Chakra de la Coronilla, 
            pasa por el Chakra del Corazón y se extiende hacia las manos, canalizando esta energía de manera intencional. Esto 
            permite al receptor balancear su sistema energético desde adentro, con la ayuda de esta energía pura y de más 
            alta vibración, sanando, resolviendo, liberando bloqueos y volviendo al centro de su ser.
            </p> 
            <p>  
            Para aquellos que necesiten una explicación un poco más fisiológica, durante el Reiki, el cuerpo
            activa el sistema nervioso parasimpático (entre otras cosas...), también conocido como "modo de descanso y digestión", y en pocas palabras,
            esto permite que el cuerpo use su energía de una manera más sabia y eficiente.
          </p> 
          <p>  
            Es una técnica "práctica" de impostación de manos. El practicante 
            coloca las manos en diferentes
            partes del cuerpo y canaliza la energía.
            Aunque hay muchas posiciones de las manos y partes del cuerpo que atender,
            Reiki siempre va donde el receptor más lo necesita.
            A medida que la energía fluye a través de nosotros y nuestros Chakras, se restablece el equilibrio,
            dando al cuerpo una sensación de paz y restaurando la habilidad de curación natural
            que tienen todos los seres vivos.
          </p> 
          <p>
            El papel del Reikista o practicante de Reiki,
            es solamente ser un canal a través del cual la energía fluye hacia el receptor. Sus ideas,
            estado emocional, intenciones y todo lo demás es secundario, ya que Reiki se encargará de
            todo por sí mismo.
          </p>
          <p>De hecho, el practicante ni siquiera necesita saber "intelectualmente" o "verbalmente"
            nada sobre el receptor o su situación, ya que, de nuevo, Reiki irá donde se necesite.
          </p>

          <img className='hand_img' src="/img/hand.png" alt="reiki symbol" />

        </div>

        {/* <img className='wind_img' src="/img/wind.png" alt="reiki symbol" /> */}
        
        <div className="about_me">
          <h3 id='sobre_mi'>Sobre Mí</h3>
          <p>
            Soy practicante de Reiki e instructor de Yoga.
            También tengo una licenciatura en producción musical y soy desarrollador web.
            Aunque todavía trabajo en esos campos, a través de experiencias de vida
            descubrí que mi propósito en la vida es ayudar y acompañar a las personas en su viaje de sanación. 
          </p>
          <p>
            Qi-Gong me hizo consciente del poder del Qi o Ki cuando se canaliza a través de las manos. Y
            eso me llevó a conocer el Reiki.
            Después de descubrirlo como "paciente" o receptor, sentí una llamada innegable a
            aprenderlo y compartirlo con los demás. Desde el día que lo aprendí y
            recibí mi primera sintonización, nunca más me alejé del Reiki, ni por un solo día,
            y encontré un propósito y un significado aún más profundos en la vida y en mi viaje de sanación personal.
          </p>

          <img className='meditating_img' src="/img/meditating_lotus.png" alt="meditating" />

        </div>

    </div>
  )
}

export default Hero;