import React, { useRef } from "react";
import { chakras_info } from "../chakras.js";
import ChakrasInteractive from "../components/english/ChakrasInteractive";
import Header from "../components/english/Header";

const Chakras = () => {
  return (
    <div className="chakras_page">
        <Header/>

      {/* Cover to avoid Mobile */}
      <div className="mobile_cover">
        <p>ONLY AVAILABLE ON DESKTOP</p>
      </div>

      <div className="chakras_text_wrapper">
        <div className="chakras_text">
          <h2>Chakras Interactive Model</h2>
          <p>
            Chakras, in a very simplified way, are energy centers in the body.
            The word comes from Sanskrit and it means wheel.
          </p>
          <p>They correspond to major organs, glands and bundles of nerves. They are naturally
            balanced but different life situations can set them off balance.
            Reiki comes in as a very useful tool and treatment to re-balance them,
            and give back the natural healing power our bodies have.
          </p>
        </div>
      </div>

      <div className="human_lotus">
        <img className="chakras_lotus" src="/img/meditating_lotus.png" alt="human_img" />

        <ul className="chakras_symbols">
          {chakras_info.map((chakra, index) => {
            return <ChakrasInteractive items={chakra} classI={`ch ch${(index+1)}`} key={index} />;
          })}
        </ul>
        
      </div>
    </div>
  );
};

export default Chakras;
