import React from 'react';
import Testimonial from '../spanish/Testimonial';
import { testimonials } from '../../testimonials';

const Testimonials = () => {
  
  return (
    <div className="testimonials_wrapper">
      <h3 className='testimonios-title'>Testimonios</h3>
      <div className= "testimonials">
        {testimonials.map((item, key) => {
          return <Testimonial testimonial={item.testimonial} name={item.name} key={key} />
        })}
      </div>

    </div>
  );
};

export default Testimonials;