import React, { useState } from 'react';

const Hero = () => {

  const [showPopUp, setShowPopUp] = useState(false);

  const navigateToGMaps = () => {
    window.location.href = "https://www.google.com/maps/place/Reikista/@55.7174335,13.2418975,18.96z/data=!4m6!3m5!1s0x4653973f39edb7e3:0x1484e52b46db8b7a!8m2!3d55.717505!4d13.2424194!16s%2Fg%2F11y5myjddk?entry=ttu";
  }

  return (
    <div className='hero'>
        
        <div className="title">
          <h1>REIKISTA</h1>
        </div>

        <div className="hero_banner">
          <p>Santiago</p>
          <h2>Usui Reiki Practitioner</h2>
          <img src="/img/reiki-symbol.png" alt="reiki symbol" />
        </div>

        <div className="button_wrapper">
          <button className='call_to_action' onClick={navigateToGMaps} onMouseEnter={()=> setShowPopUp(true)} onMouseLeave={()=>setShowPopUp(false)}>BOOK A SESSION</button>
          {showPopUp ?
          <div className="pop_up">
            <p>Area: 📍 Lund, Sweden</p>
            <p>Send an Email to: vazquezbare@gmail.com</p>
          </div>
          : null}
        </div>
        

        <div className="description">
          <h3 id='about_reiki'>About Reiki</h3>
          <p>
            Reiki is a form of healing through the flow of universal life force energy.
            It works on our physical body, our emotional body and our mind, so during a session
            you can expect to feel emotions, or to have specific thoughts that call your attention,
            but mostly, people feel a deep sense of relaxation and peace. This is the body 
            activating the parasympathetic nervous system, also know as "rest and digest mode", and simply put,
            this allows the body to use it's energy in a wiser and more efficient way.
          </p> 
          <p>  
            Reiki is a "hands-on" technique, the practitioner positions the hands on different
            parts of the body and channels the energy.
            Even though there are many hand positions and body spots to take care of,
            Reiki always goes to where the receptor most needs it. 
            As the energy flows through us and our Chakras, balance is re-established,
            giving the body a feeling of peace, and restoring the natural healing 
            hability all living beings have.
          </p> 
          <p>
            The role of the Reikista or the Reiki practitioner,
            is only to be a channel through which the energy flows to the recipient. Their ideas,
            emotional state, intentions and all the rest is secondary, since Reiki will take care of
            everything by itself.
          </p>
          <p>As a matter of a fact, the practitioner doesn't even need to know "intellectually" or "verbally"
            anything about the receptor or their situation, since, again, Reiki will go where it is needed.
          </p>

          <img className='hand_img' src="/img/hand.png" alt="reiki symbol" />

        </div>

        {/* <img className='wind_img' src="/img/wind.png" alt="reiki symbol" /> */}
        
        <div className="about_me">
          <h3 id='about_me'>About Me</h3>
          <p>
            I am a Reiki practitioner, and a Yoga instructor with 15 years of experience.
            I also have a Bachelor's degree in Music Production, and I'm also a Web Developer.
            My purpose is about helping people to heal.
          </p>
          <p>
            Qi-Gong made me aware of the power or Qi or Ki when channeled through the hands. And 
            that took me to Reiki.
            After discovering Reiki as a "patient" or receiver, I felt an undeniable call
            to learn it and share it with others. Since the day that I learned Reiki and
            received my attunement, I've been doing Reiki every single day,
            and I found an even deeper purpose and meaning in life and in my personal healing journey.
          </p>

          <img className='meditating_img' src="/img/meditating_lotus.png" alt="meditating" />

        </div>

    </div>
  )
}

export default Hero;